@import 'metronic/theme/style.scss';
@import 'sintrapp';

$main: kt-get($kt-state-colors, brand, base);
$light: kt-get($kt-state-colors, primary, base);

@import 'reset';
@import 'utils';
@import 'modal';
@import 'login';
@import 'tree';

body,
html,
#root,
.login-wrapper {
	width: 100%;
	min-height: 100vh;
	display: flex;
}

.no-wrap {
	white-space: nowrap;
}

.no-width {
	width: 1%;
}

.table-loading {
	position: relative;
	zoom: 1;
}

.blockui-overlay {
	z-index: 10;
	border: none;
	margin: 0;
	padding: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: default;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
}

.position-relative {
	position: relative;
}

.flexTable {
	display: flex;

	.currency {
		flex: 1;
		text-align: left;
	}
}

.report-irregular figure {
	width: 300px;
	padding: 10px 20px;
}

.blockui {
	background: #fff;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	display: table;
	table-layout: fixed;
	border-radius: 4px;

	span {
		color: #74788d;
		display: table-cell;
		vertical-align: middle;
		padding: 0.75rem 1.2rem;
		font-size: 1rem;
		font-weight: 400;

		> .kt-spinner {
			margin-right: 1.25rem;
		}
	}
}

.table-drop.show .dropdown-menu {
	top: 26px;
	right: 10px;
	margin: 0;
	left: auto;

	i {
		font-size: 1rem;
	}

	.dropdown-item {
		align-items: center;
	}
}

.map {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 650px;
}

i.my-location {
	font-size: 25px;
	margin-left: 10px;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.7);
}

pre {
	white-space: pre-wrap;
	word-wrap: break-word;
	margin-top: 10px;
	padding: 10px;
	background-color: #ffe2e8;
	border-radius: 5px;
	color: #db264a;

	code {
		background: transparent;
	}
}

.alert {
	pre,
	code {
		font-size: 1rem;
		font-weight: bold;
		font-family: inherit;
	}

	pre code {
		display: block;
		white-space: normal;
		background: transparent;
		color: #fd397a;
	}
}

.kt-header__topbar {
	.kt-badge--username {
		color: kt-get($kt-state-colors, brand, base);
		background: rgba(kt-get($kt-state-colors, brand, base), 0.1);
	}

	.dropdown-menu-right {
		left: auto;
		right: 15px;
	}
}

.kt-notification .kt-notification__item:after {
	font-family: 'Font Awesome 5 Free';
	content: '\f105';
	font-weight: 900;
}

.kt-header__brand-logo,
.kt-header-mobile__logo {
	img {
		height: 40px;
	}
	span {
		color: #fff;
		font-size: 1.1rem;
		padding-left: 12px;
	}
}

.kt-datatable__cell {
	&.vertical-top {
		vertical-align: top !important;
	}
	&.width-30 {
		width: 30%;
	}

	.react-select__control {
		min-width: 140px;
		border: 1px solid $input-border-color;

		&:hover {
			border-color: $input-border-color;
		}
	}

	.react-select__control--is-focused {
		border-color: $input-focus-border-color;
		box-shadow: 0 0 0 1px $input-focus-border-color;

		&:hover {
			border-color: $input-focus-border-color;
		}
	}

	.pre-wrap {
		white-space: pre-wrap;
	}
}

.form-group {
	.react-select__control {
		border: 1px solid $input-border-color;

		&:hover {
			border-color: $input-border-color;
		}
	}

	.react-select__control--is-focused {
		border-color: $input-focus-border-color;
		box-shadow: 0 0 0 1px $input-focus-border-color;

		&:hover {
			border-color: $input-focus-border-color;
		}
	}

	.react-select__placeholder {
		color: $input-placeholder-color;
	}

	.react-select__multi-value__label {
		font-weight: 500;
	}

	.is-invalid .react-select__control {
		border-color: kt-state-color(danger, base);
	}
}

.name-icon i {
	margin-left: 5px;
}

.loading {
	padding: 10px;
	padding-bottom: 35px;
	margin: 0 auto;
}

.button-add {
	margin-right: 15px;
}

.map--marker {
	font-size: 16px;
}

.kt-header__topbar-item.kt-header__topbar-item--user {
	.dropdown-menu {
		&.show {
			display: block;
		}

		display: none;
	}
}

.kt-grid.kt-grid--hor.kt-grid--root.kt-header__topbar--mobile-on {
	.dropdown-menu {
		display: block;
		top: 60px;
		right: 0;
		left: unset;
	}
}

.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row.kt-street_row {
	border-color: transparent;
}

.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row.kt-street_row td {
	padding-left: 50px !important;
}

#root .kt-grid.kt-grid--hor.kt-grid--root {
	overflow-x: hidden;

	.list.kt-datatable.kt-datatable--default.kt-datatable--brand.kt-datatable--loaded table {
		display: block;
		overflow-x: auto;
	}
}

.kt-datatable__row.bold {
	font-weight: bold;
}

@media (max-width: 1024) {
	.kt-content.kt-grid__item {
		&.kt-grid__item--fluid {
			flex: none;
			display: block;
			width: 96%;
			padding: 0 15px;
		}

		.list.kt-datatable.kt-datatable--default.kt-datatable--brand.kt-datatable--loaded {
			overflow-x: auto;

			table {
				min-width: 320px;
			}
		}
	}
}

@media (max-width: 768px) {
	.kt-header-mobile {
		&.kt-header-mobile--fixed {
			flex-direction: row;
		}

		.kt-header-mobile__logo {
			flex: 3;
		}

		.kt-header-mobile__toolbar {
			flex: 1;
		}
	}

	.kt-aside.kt-aside--fixed.kt-grid__item.kt-grid.kt-grid--desktop.kt-grid--hor-desktop {
		left: -375px;
	}

	.kt-aside--on {
		.kt-aside.kt-aside--fixed.kt-grid__item.kt-grid.kt-grid--desktop.kt-grid--hor-desktop {
			left: 0px;
		}
	}
}

@media (max-width: 420px) {
	.rccs {
		display: none;
	}

	.kt-header-mobile__logo {
		span {
			display: none;
		}
	}
}

.image-inspection {
	max-width: 150px;
	max-height: 200px;
	width: auto;
	height: auto;
	margin: 5px;
}

.login-box .logo {
	padding: 0;
	img {
		border-radius: 6px;
	}
}

.marginLeft {
	display: flex;
	align-items: center; // vertically center
	justify-content: flex-end;
	margin-right: 20px;
}


.fields-check {
	display: flex;
	flex-direction: column;
}

.fields-check .btn {
	margin-bottom: 15px;
	align-self: flex-end;
}

.fields-check .boxes {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	flex: 1;
	margin-top: 15px;
	margin-bottom: 30px;
}

.fields-check label {
	margin-left: 8px;
}
.font-title {
  font-family: Pattaya,sans-serif;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107,114,128,var(--tw-text-opacity));
}
.md\:text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.md\:text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.font-bold {
  font-weight: 700;
}
.justify-center {
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex {
  display: -webkit-flex;
  display: flex;
}
.mt-10 {
  margin-top: 2.5rem;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.duration-150, .transition {
  transition-duration: .15s;
}
.transition {
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.hover\:shadow-md:hover, .shadow-inner {
  box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
}
.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.06);
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245,158,11,var(--tw-text-opacity));
}
.p-5 {
  padding: 1.25rem;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--tw-bg-opacity));
}
.border {
  border-width: 1px;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229,231,235,var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: .5rem;
}
.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245,158,11,var(--tw-border-opacity));
}
.border-solid {
  border-style: solid;
}
.border-2 {
  border-width: 2px;
}
.rounded-full {
  border-radius: 9999px;
}
.cursor-pointer {
  cursor: pointer;
}
.md\:p-5 {
  padding: 1.25rem;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
}
.shadow, .shadow-lg {
  box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156,163,175,var(--tw-text-opacity));
}
.md\:text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-lg, .text-xl {
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
}
.p-2 {
  padding: .5rem;
}
.w-auto {
  width: auto;
}
.m-5 {
  margin: 1.25rem;
}
.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.m-2 {
  margin: .5rem;
}
.text-center {
  text-align: center;
}
.w-full {
  width: 100%;
}


