//
// Base
//

// Body
body {
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-color: $kt-page-body-bg-color;
}

// Page Fixed Mode(above $kt-page-container-width)
@include kt-page-fixed-mode {
    // Container
    .kt-container {
        width: $kt-page-container-width;
        margin: 0 auto;
    }

    // Page Fluid
    .kt-page--fluid {
        .kt-container {
            width: 100%;
            padding: 0 $kt-page-fluid-container-padding;
        }
    }
}

// Page fluid mode(below $kt-page-container-width)
@include kt-page-fluid-mode {
    // Container
    .kt-container {
        width: 100%;
        padding: 0 kt-get($kt-page-padding, desktop);
    }
}

// Desktop
@include kt-desktop() {
    // Subheader
    .kt-subheader {
        padding-right: 0 !important;

        // aside disabled
        body:not(.kt-aside--enabled) & {
            padding-left: 0;
        }
    }

    // Body holder
    .kt-holder {
        padding-top: kt-get($kt-header-config, base, desktop, default, height);

        // Aside fixed
        .kt-aside--enabled.kt-aside--fixed & {
            padding-left: kt-get($kt-aside-config, base, default, width);
        }
    }
}

// Tablet & Mobile Mode(1024px and below)
@include kt-tablet-and-mobile() {
    // Container
    .kt-container {
        padding: 0 kt-get($kt-page-padding, mobile);
    }

    .kt-holder {
        padding-top: kt-get(
            $kt-header-config,
            base,
            mobile,
            self,
            default,
            height
        );
    }

    // Subheader
    .kt-subheader {
        padding: 10px 0 !important;
    }
}
