//
// Topbar
//



$kt-topbar-icon-color: rgba(#fff, 0.8);
$kt-topbar-mobile-icon-color: kt-base-color(label, 2);

$kt-topbar-icon-size: 37px;
$kt-topbar-mobile-icon-size: 30px;

// General mode
.kt-header__topbar {
	display: flex;
	align-items: stretch;
	padding: 0;

	.kt-header__topbar-item {
		display: flex;
		align-items: stretch;
		margin: 0 0.36rem;

		.kt-header__topbar-wrapper {
			cursor: pointer;
			display: flex;
			align-items: stretch;

			.kt-header__topbar-icon {
				display: flex;
				align-items: center;
				align-self: center;
				justify-content: center;
				height: $kt-topbar-icon-size;
				width: $kt-topbar-icon-size;
				border-radius: $kt-border-radius;
				cursor: pointer;
				background: transparent;
				@include kt-transition();

				i {
					line-height: 0;
					font-size: 1.4rem;
					color: $kt-topbar-icon-color;
				}

				b {
					font-size: 2rem;
					color: #fff;
					line-height: 0;
					font-weight: 500;
				}
			}
		}

		// Languages
		&.kt-header__topbar-item--langs {
			.kt-header__topbar-icon {
				height: $kt-topbar-icon-size;
				width: $kt-topbar-icon-size;
				border-radius: $kt-border-radius;

				img {
					border-radius: $kt-border-radius;
					width: 22px;
				}
			}

			.dropdown-menu {
				.kt-nav__link-icon {
					padding-right: 10px;

					img {
						border-radius: 50%;
						width: 18px;
					}
				}
			}
		}

		// User profile
		&.kt-header__topbar-item--user {
			//padding: 0 0.4rem;

			.kt-header__topbar-welcome {
				display: flex;
				align-self: center;
				padding: 0 0.55rem 0 0;
				font-weight: 500;
				font-size: 0.9rem;
				color: #bbb;
			}

			.kt-header__topbar-username {
				display: flex;
				align-self: center;
				padding: 0 0.55rem 0 0;
				font-weight: 500;
				font-size: 1rem;
				color: #fff;
			}

			.kt-header__topbar-wrapper {
				img {
					align-self: center;
					height: $kt-topbar-icon-size;
					border-radius: $kt-border-radius;
				}
			}
		}

		// Hover state
		.show &,
		&:hover {
			.kt-header__topbar-wrapper {
				.kt-header__topbar-icon {
					@include kt-transition();
					background-color: rgba(kt-get($kt-header-config, base, self, bg-color), 0.1);
				}
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

// Tablet and mobile mode(1024px and below)
@include kt-tablet-and-mobile() {
	// Toolbar
	.kt-header__topbar {
		padding: 0 kt-get($kt-page-padding, mobile);
		background-color: #fff;
		transition: all 0.3s ease;
		margin-top: -(kt-get($kt-header-config, topbar, height, mobile));
		height: kt-get($kt-header-config, topbar, height, mobile);
		position: absolute;
		left: 0;
		right: 0;
		justify-content: flex-end;
		width: 100%;

		// Fixed mobile header
		.kt-header-mobile--fixed & {
			position: fixed;
			z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex) - 1;
		}

		// Topbar shown
		.kt-header__topbar--mobile-on & {
			margin-top: 0;
			transition: all 0.3s ease;
			border-top: 1px solid #ebedf2;
			box-shadow: 0px 4px 30px -4px rgba(0,51,90, 0.1);
			background: #fff;
		}

		// Topbar item
		.kt-header__topbar-item {
			margin: 0 0.25rem;

			// Wrapper
			.kt-header__topbar-wrapper {
				.kt-header__topbar-icon {
					max-height: $kt-topbar-mobile-icon-size;
					max-width: $kt-topbar-mobile-icon-size;
					border: 0;

					i {
						font-size: 1.3rem;
						color: $kt-topbar-mobile-icon-color;
					}

					b {
						color: #fff;
					}
				}
			}

			// User
			&.kt-header__topbar-item--user {
				margin: 0;

				.kt-header__topbar-wrapper {
					img {
						height: $kt-topbar-mobile-icon-size;
					}
				}
			}

			// Hover state
			.show &,
			&:hover {
				.kt-header__topbar-wrapper {
					.kt-header__topbar-icon {
						background-color: rgba(#000, 0.05);

						i {
							color: kt-brand-color();
						}

						b {
							color: #fff;
						}
					}
				}
			}

			&:last-child {
				padding-right: 0 !important;
				margin-right: 0 !important;
			}
		}
	}
}
