//
// Global Config
//

// Functions
@import "core/functions";

// Mixins
@import "core/mixins";
@import "vendors/mixins/perfect-scrollbar";
@import "core/base/mixins/grid";
@import "core/base/mixins/burger-menu";
@import "core/base/mixins/menu-horizontal";
@import "core/base/mixins/menu-vertical";
@import "core/base/mixins/offcanvas";
@import "core/base/mixins/spinner";

// Theme mixins
@import "core/layout/mixins/app";
@import "core/typography/mixins/link";
@import "core/general/mixins/user-pic";

// Layout Breakpoints(bootstrap responsive breakpoints)
$kt-media-breakpoints: (
    // Extra small screen / phone
    xs: 0,

    // Small screen / phone
    sm: 576px,

    // Medium screen / tablet
    md: 768px,

    // Large screen / desktop
    lg: 1024px,

    // Extra large screen / wide desktop
    xl: 1399px
);

// Layout
$kt-aside-default-width: 255px;
$kt-aside-offcanvas-width: 255px;

// Global rounded border mode
$kt-rounded: true;

// Border Radius
$kt-border-radius: 4px;

// Core icon codes(lineawesome icons: https://icons8.com/line-awesome)
$kt-action-icons: (
    close: '\f191',
    down: '\f110',
    up: '\f113',
    left: '\f111',
    right: '\f112',
    plus: '\f2c2',
    minus: '\f28e'
);

// Core bold icon codes(lineawesome icons: https://keenthemes.com/metronic/preview/default/components/icons/flaticon.html)
$kt-action-bold-icons: (
    down: '\f1b1',
    up: '\f1b3',
    left: '\f1b2',
    right: '\f1a9'
);

// Elevate shadow
$kt-elevate-shadow: 0px 0px 13px 0px rgba(82,63,105,0.05);
$kt-elevate-shadow-2: 0px 0px 13px 0px rgba(82,63,105,0.1);

// Dropdown shadow
$kt-dropdown-shadow: 0px 0px 50px 0px rgba(82,63,105, 0.15);

// Custom scrollbar color
$kt-scroll-color: darken(#ebedf2, 6%);

// Transition
$kt-transition: all 0.3s;

// Modal Z-index
$kt-modal-zindex: 1050;

// dropdown Z-index
$kt-dropdown-zindex: 95;

// Brand Color
$kt-brand-hover-color: #f5f6fc;
$kt-brand-inverse-color: #ffffff;

// State colors
$kt-text-color: #646c9a !default;
$kt-state-colors: (
    // Metronic states
    brand: (
        base: #304e84,
        inverse: #ffffff
    ),
    light: (
        base: #fff,
        inverse: #5386e5
    ),
    dark: (
        base: #1e355f,
        inverse: #ffffff
    ),
    primary: (
        base: #5386e5,
        inverse: #ffffff
    ),
    success: (
        base: #1dc9b7,
        inverse: #ffffff
    ),
    info: (
        base: #5186e5,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #111111
    ),
    danger: (
        base: #fd397a,
        inverse: #ffffff
    )
) !default;

// Base colors
$kt-base-colors: (
    label: (
        1: hsl(219, 19%, 73%),
        2: hsl(219, 19%, 71%),
        3: hsl(219, 15%, 50%),
        4: hsl(219, 12%, 30%)
    ),
    shape: (
        1: hsl(219, 100%, 97%),
        2: hsl(219, 64%, 95%),
        3: hsl(219, 52%, 72%),
        4: hsl(219, 21%, 50%)
    ),
    grey: (
        1: #f7f8fa, //#f4f5f8
        2: #ebedf2,
        3: darken(#ebedf2, 3%),
        4: darken(#ebedf2, 6%)
    )
) !default;

// Social network colors
$kt-social-colors: (
    facebook: (
        base: #3b5998,
        inverse: #ffffff
    ),
    google: (
        base: #dc4e41,
        inverse: #ffffff
    ),
    twitter: (
        base: #4AB3F4,
        inverse: #ffffff
    ),
    instagram: (
        base: #517fa4,
        inverse: #ffffff
    ),
    youtube: (
        base: #b31217,
        inverse: #ffffff
    ),
    linkedin: (
        base: #0077b5,
        inverse: #ffffff
    )
);

// Root Font Settings
$kt-font-families: (
    regular: 'Poppins',
    heading: 'Poppins'
);

// Root Font Settings
$kt-font-size: (
    size: (
        desktop: 13px,
        tablet: 12px,
        mobile: 12px
    ),
    weight: 400
);

// General Link Settings
$kt-font-color: (
    text: $kt-text-color,
    link: (
        default: kt-state-color(brand),
        hover: darken(kt-state-color(brand), 6%)
    )
);

// Portlet settings
$kt-portlet: (
    min-height: (
        default: (
            desktop: 60px,
            mobile: 50px
        ),
        sm: (
            desktop: 50px,
            mobile: 40px
        ),
        lg: (
            desktop: 80px,
            mobile: 60px
        ),
        xl: (
            desktop: 100px,
            mobile: 80px
        )
    ),
    space: (
        desktop: 25px,
        mobile: 15px
    ),
    bottom-space: (
        desktop: 20px,
        mobile: 20px
    ),
    border-color: kt-base-color(grey, 2),
    bg-color: #ffffff,
    shadow: 0px 0px 30px 0px rgba(82,63,105,0.05)
);

// Page padding
$kt-page-padding: (
    desktop: 30px,
    mobile: 15px
);

$kt-page-body-bg-color: #f9f9fc;
$kt-page-container-width: 1380px;
$kt-page-fluid-container-padding: 80px;

// Custom Scroll(Perfect Scrollbar) size
$kt-custom-scroll-size: 4px;

// Layout mixins
@mixin kt-page-fixed-mode {
	@media ( min-width: #{$kt-page-container-width + (2 * $kt-page-fluid-container-padding)} ) {
        @content;
    }
}

@mixin kt-page-fluid-mode {
	@media (max-width: #{$kt-page-container-width + (2 * $kt-page-fluid-container-padding) - 1px}) {
        @content;
    }
}
