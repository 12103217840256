.login-wrapper {
  background: linear-gradient(-135deg, $light, $main);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.login-box {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 40px;
  margin: 40px 0;
  position: relative;

  .retry-error {
    margin-bottom: 35px;
    text-align: center;
    font-size: 16px;
    color: red;
  }

  .back {
    position: absolute;
    padding: 1.5rem 1.2rem;
    top: 0;
    left: 0;
    opacity: 0.5;
    transition: opacity 0.3s;
    border: none;
    background: none;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    svg {
      width: 35px;
    }
  }

  .alert {
    margin: 25px 0 0;
  }

  .btn {
    line-height: 1rem;
    background-color: $main;
    border-color: $main;
    padding: 1.75rem;

    &:disabled {
      padding: 1.6rem;
    }

    &:not(:disabled):hover {
      background-color: darken($light, 20);
      border-color: darken($light, 20);
    }

    &:not(:disabled):active {
      background-color: darken($light, 15);
      border-color: darken($light, 15);
    }
  }

  .invalid-feedback {
    font-size: 13px;
    margin-top: 6px;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    position: relative;

    img {
      max-width: 100%;
      max-height: 200px;
      width: auto;
    }
  }
}

.recover-link {
  margin-top: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  button,
  i {
    font-size: 15px;
    border: none;
    background: transparent;
    text-align: center;
    color: $main;
    font-weight: 600;
  }
}

.login-title {
  margin: 20px 0 25px;
  text-align: center;

  h3,
  button {
    color: $main;
  }

  h3 {
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
  }

  span,
  button {
    display: inline-block;
    margin-top: 3px;
    font-size: 14px;
    border: none;
    background: transparent;
    font-weight: 400;
  }
}

.login-subtitle {
  margin: 0 0 25px;
  text-align: center;
  color: $dark;

  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.login-description {
  margin: 0 0 25px;
  text-align: justify;
  font-size: 12px;
  color: $dark;

  p {
    margin: 0 0 6px;
  }
}

.login-page {
  position: relative;
  float: left;
  left: 100%;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 250ms;

  &.show {
    opacity: 1;
    left: 0;
  }
}

@include tablet() {
  .login-box {
    max-width: 450px;
    padding: 50px 60px;
  }
}
